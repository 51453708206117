/* Hero.css */
.hero {
    position: relative;
    background: url('/public/images/destruction.png') no-repeat center center; /* Verify the image path */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;

    /* Adjusted aspect ratio for responsiveness */
    aspect-ratio: 1920 / 640;
    @media (max-width: 768px) {
        aspect-ratio: 768 / 300; /* More suitable for smaller screens */
    }

    margin: 0 auto;
    color: white;
    text-align: left;
    /* Reduced padding and made responsive */
    padding: 2em 1em;
    @media (max-width: 768px) {
        padding: 1em 0.5em; /* Even smaller padding on mobile devices */
    }

    display: flex;
    align-items: center;
    justify-content: center; /* Center aligned for better uniformity */
}

.top-gradient {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 30%; /* Adjust height as needed */
    background: linear-gradient(
            to bottom,
            rgba(3, 3, 9, 1), /* Semi-transparent at the top */
            rgba(3, 3, 9, 0)    /* Transparent by the bottom of the section */
    );
    z-index: 1;
}

.hero-content {
    position: absolute;
    top: 22%; /* Adjust this value to move the text up or down */
    left: 5%; /* Adjust horizontal positioning as necessary */
    color: #deba3b; /* Change the text color to black */
    z-index: 2; /* Ensure the text appears above gradients and background */
    max-width: 500px; /* Optional: control text width */
    /* Responsive font sizes */
    h1 {
        font-size: calc(1.5em + 1vw); /* Responsive font size for headings */
        margin-bottom: 0.5em; /* Optional: spacing between heading and paragraph */
    }
    p {
        font-size: calc(1em + 0.5vw); /* Responsive font size for paragraphs */
        margin-bottom: 1em;
    }
}

.hero-logo {
    position: absolute;
    bottom: 10%;
    right: 5%;
    width: 300px; /* Adjust size as needed */
    z-index: 2;
}

.bottom-gradient {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30%; /* Adjust height as needed */
    background: linear-gradient(
            to top,
            rgba(3, 3, 9, 1), /* Dark at the bottom */
            rgba(3, 3, 9, 0)    /* Transparent by the top of the section */
    );
    z-index: 1;
}

.learn-more-btn {
    background-color: #fdee21; /* Button color */
    border: none;
    border-radius: 20px;
    padding: 0.5em 1.5em;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.2s;
    &:hover {
        background-color: #f7b907; /* Darken on hover */
    }
}
