/* PlanCard.css */
.plan-card {
    background-color: #27231b;
    padding: 1em;
    margin: 1em;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    transform: translate3d(0, 0, 0); /* Ensuring a 3D transform context */
    display: inline-block; /* Ensuring it can transform properly */
}

.plan-card:hover {
    transform: translate3d(0, -10px, 0) rotateY(10deg);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}


.plan-icon {
    font-size: 2em;
    margin-bottom: 0.5em;
}

.plan-card h3 {
    margin: 0.5em 0;
    font-size: 1.2em;
    color: #deba3b;
}

.plan-card p {
    font-size: 0.9em;
    color: #d3d3d3;
}
