/* OfferBlock.css */
.offer-block {
    background-color: #2b2b2b;
    border-radius: 10px;
    padding: 1em 1.5em;
    width: 200px; /* Adjust width */
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    transform: translate3d(0, 0, 0); /* Ensuring a 3D transform context */
}

.offer-block:hover {
    transform: translate3d(0, -10px, 0) rotateY(10deg);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.offer-icon {
    font-size: 2em;
    margin-bottom: 0.5em;
}

.offer-block h3 {
    margin: 0.5em 0;
}

.offer-block p {
    font-size: 0.9em;
    color: #d3d3d3;
}
