/* About.css */
.about {
    position: relative;
    color: #f5f5f5; /* Light text on a dark background */
    padding: 4em 2em;
    background-color: #000000; /* Completely black background */
    text-align: center; /* Center the text horizontally */
    display: flex;
    flex-direction: column;
    align-items: center; /* Center child elements horizontally */
    gap: 2em;
}

.about h2 {
    font-size: 2.5em;
    text-align: center; /* Ensure the heading text is centered */
    margin: 0; /* Remove default margins for proper spacing */
    z-index: 1;
}

.about p {
    font-size: 1.1em;
    max-width: 600px;
    text-align: center; /* Center the paragraph text */
    margin: 0 auto; /* Center horizontally within the section */
    z-index: 1;
}

.offer-blocks {
    display: flex;
    gap: 1.5em;
    justify-content: center; /* Center the offer blocks */
    z-index: 1;
}

/* Overlay Image with Gradient */
.about-image {
    position: absolute;
    top: 0;
    right: 0;
    width: 400px; /* Adjust size as needed */
    height: 100%; /* Full height of the section */
    background: linear-gradient(
            to left,
            rgba(3, 3, 9, 0.35), /* Gradient effect from the right */
            rgba(3, 3, 9, 1)
    ),
    url('/public/images/heidi.jpeg'); /* Adjust the image path */
    background-size: cover;
    background-position: center;
    z-index: 0; /* Place the image below the text content */
}

/* Overlay Image with Gradient on the Left */
.about-image-left {
    position: absolute;
    top: 0;
    left: 0;
    width: 400px; /* Adjust size as needed */
    height: 100%; /* Full height of the section */
    background: linear-gradient(
            to right,
            rgba(3, 3, 9, 0.35), /* Gradient effect from the left */
            rgba(3, 3, 9, 1)
    ),
    url('/public/images/Group2.png'); /* Specify your second image path */
    background-size: cover;
    background-position: center;
    z-index: 0; /* Place the image below the text content */
}
